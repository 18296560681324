!(function ($) {
    "use strict";
    $.fn.meanmenu = function (e) {
        var n = {
            meanMenuTarget: jQuery(this),
            meanMenuContainer: ".mobile-responsive-menu",
            meanMenuClose: "X",
            meanMenuCloseSize: "18px",
            meanMenuOpen: "<span /><span /><span />",
            meanRevealPosition: "right",
            meanRevealPositionDistance: "0",
            meanRevealColour: "",
            meanScreenWidth: "480",
            meanNavPush: "",
            meanShowChildren: !0,
            meanExpandableChildren: !0,
            meanExpand: "+",
            meanContract: "-",
            meanRemoveAttrs: !1,
            onePage: !1,
            meanDisplay: "block",
            removeElements: "",
        };
        e = $.extend(n, e);
        var a = window.innerWidth || document.documentElement.clientWidth;
        return this.each(function () {
            var n = e.meanMenuTarget,
                t = e.meanMenuContainer,
                r = e.meanMenuClose,
                i = e.meanMenuCloseSize,
                s = e.meanMenuOpen,
                u = e.meanRevealPosition,
                m = e.meanRevealPositionDistance,
                l = e.meanRevealColour,
                o = e.meanScreenWidth,
                c = e.meanNavPush,
                v = ".meanmenu-reveal",
                h = e.meanShowChildren,
                d = e.meanExpandableChildren,
                y = e.meanExpand,
                j = e.meanContract,
                Q = e.meanRemoveAttrs,
                f = e.onePage,
                g = e.meanDisplay,
                p = e.removeElements,
                C = !1;
            (navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/Blackberry/i) ||
                navigator.userAgent.match(/Windows Phone/i)) &&
                (C = !0),
                (navigator.userAgent.match(/MSIE 8/i) || navigator.userAgent.match(/MSIE 7/i)) && jQuery("html").css("overflow-y", "scroll");
            var w = "",
                x = function () {
                    if ("center" === u) {
                        var e = window.innerWidth || document.documentElement.clientWidth,
                            n = e / 2 - 22 + "px";
                        (w = "left:" + n + ";right:auto;"), C ? jQuery(".meanmenu-reveal").animate({ left: n }) : jQuery(".meanmenu-reveal").css("left", n);
                    }
                },
                A = !1,
                E = !1;
            "right" === u && (w = "right:" + m + ";left:auto;"), "left" === u && (w = "left:" + m + ";right:auto;"), x();
            var M = "",
                P = function () {
                    M.html(jQuery(M).is(".meanmenu-reveal.meanclose") ? r : s);
                },
                W = function () {
                    jQuery(".mean-bar,.mean-push").remove(), jQuery(t).removeClass("mean-container"), jQuery(n).css("display", g), (A = !1), (E = !1), jQuery(p).removeClass("mean-remove");
                },
                b = function () {
                    var e = "background:" + l + ";color:" + l + ";" + w;
                    if (o >= a) {
                        jQuery(p).addClass("mean-remove"),
                            (E = !0),
                            jQuery(t).addClass("mean-container"),
                            jQuery(".mean-container").prepend('<div class="mean-bar"><a href="#nav" class="meanmenu-reveal" aria-label="nav toggle" style="' + e + '">Show Navigation</a><nav class="mean-nav"></nav></div>');
                        var r = jQuery(n).html();
                        jQuery(".mean-nav").html(r),
                            Q &&
                                jQuery("nav.mean-nav ul, nav.mean-nav ul *").each(function () {
                                    jQuery(this).is(".mean-remove") ? jQuery(this).attr("class", "mean-remove") : jQuery(this).removeAttr("class"), jQuery(this).removeAttr("id");
                                }),
                            jQuery(n).before('<div class="mean-push" />'),
                            jQuery(".mean-push").css("margin-top", c),
                            jQuery(n).hide(),
                            jQuery(".meanmenu-reveal").show(),
                            jQuery(v).html(s),
                            (M = jQuery(v)),
                            jQuery(".mean-nav ul").hide(),
                            h
                                ? d
                                    ? (jQuery(".mean-nav ul ul").each(function () {
                                          jQuery(this).children().length &&
                                              jQuery(this, "li:first")
                                                  .parent()
                                                  .append('<a class="mean-expand" href="#" style="font-size: ' + i + '">' + y + "</a>");
                                      }),
                                      jQuery(".mean-expand").on("click", function (e) {
                                          e.preventDefault(),
                                              jQuery(this).hasClass("mean-clicked")
                                                  ? (jQuery(this).text(y),
                                                    jQuery(this)
                                                        .prev("ul")
                                                        .slideUp(300, function () {}))
                                                  : (jQuery(this).text(j),
                                                    jQuery(this)
                                                        .prev("ul")
                                                        .slideDown(300, function () {})),
                                              jQuery(this).toggleClass("mean-clicked");
                                      }))
                                    : jQuery(".mean-nav ul ul").show()
                                : jQuery(".mean-nav ul ul").hide(),
                            jQuery(".mean-nav ul li").last().addClass("mean-last"),
                            M.removeClass("meanclose"),
                            jQuery(M).click(function (e) {
                                e.preventDefault(),
                                    A === !1 ? (M.css("text-align", "center"), M.css("text-indent", "0"), M.css("font-size", i), jQuery(".mean-nav ul:first").slideDown(), (A = !0)) : (jQuery(".mean-nav ul:first").slideUp(), (A = !1)),
                                    M.toggleClass("meanclose"),
                                    P(),
                                    jQuery(p).addClass("mean-remove");
                            }),
                            f &&
                                jQuery(".mean-nav ul > li > a:first-child").on("click", function () {
                                    jQuery(".mean-nav ul:first").slideUp(), (A = !1), jQuery(M).toggleClass("meanclose").html(s);
                                });
                    } else W();
                };
            C ||
                jQuery(window).resize(function () {
                    (a = window.innerWidth || document.documentElement.clientWidth), a > o, W(), o >= a ? (b(), x()) : W();
                }),
                jQuery(window).resize(function () {
                    (a = window.innerWidth || document.documentElement.clientWidth), C ? (x(), o >= a ? E === !1 && b() : W()) : (W(), o >= a && (b(), x()));
                }),
                b();
        });
    };
})(jQuery);

$(document).ready(function () {
    var _rtl = false;
    if ($("body").hasClass("rtl")) {
        _rtl = true;
    }

    $('.mean-menu').meanmenu({
        meanScreenWidth: "991"
    });

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 100) {
            $('.navbar-area').addClass("is-sticky");
        }
        else {
            $('.navbar-area').removeClass("is-sticky");
        };
    });

    // Go to Top
    $(window).on('scroll', function () {
        var scrolled = $(window).scrollTop();
        if (scrolled > 300) $('.go-top').addClass('active');
        if (scrolled < 300) $('.go-top').removeClass('active');
    });

    // Click Event
    $('.go-top').on('click', function () {
        $("html, body").animate({ scrollTop: "0" }, 500);
    });

    $('.team-active').slick({
        dots: true,
        infinite: true,
        arrows: false,
        speed: 800,
        margin:15,
        slidesToShow: 4,
        slidesToScroll: 4,
        rtl: _rtl,
        autoplay: true,
        autoplaySpeed: 1700,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.services-slider').slick({
        dots: true,
        infinite: true,
        arrows: false,
        speed: 900,
        margin:20,
        slidesToShow: 3,
        slidesToScroll: 3,
        rtl: _rtl,
        autoplay: true,
        autoplaySpeed: 1800,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.before-after-slider').slick({
        dots: true,
        infinite: true,
        arrows: false,
        speed: 1000,
        margin:50,
        slidesToShow: 4,
        slidesToScroll: 4,
        rtl: _rtl,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1.3
                }
            }
        ]
    });

    $('.solution-slider').slick({
        infinite: true,
        margin: 15,
        rtl:_rtl,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1.3
                }
            }
        ]
    });

    $('.partners-slider').slick({
        dots: false,
        infinite: true,
        arrows: false,
        speed: 1000,
        margin:50,
        slidesToShow: 4,
        slidesToScroll: 4,
        rtl: _rtl,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });

    new WOW().init();
});
